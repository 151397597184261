<template>
  <div>
    <b-field
      grouped
    >
      <b-field
        label="Год"
        :label-position="'on-border'"
      >
        <b-select
          v-model="filterYear"
          size="is-small"
        >
          <option
            v-for="year in [2023, 2022, 2021]"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </b-select>
        <p class="control">
          <b-button
            size="is-small"
            type="is-primary is-light"
            label="очистить"
            @click="filterYear = null"
          />
        </p>
      </b-field>

      <b-field
        label="Глава"
        :label-position="'on-border'"
      >
        <b-select
          value="ChartsFilter"
          size="is-small"
          @input="setFilter"
        >
          <option
            v-for="chapter in Chapters"
            :key="'chapter' + chapter.id"
            :value="chapter"
          >
            {{ chapter.name }}
          </option>
        </b-select>
        <p class="control">
          <b-button
            size="is-small"
            type="is-primary is-light"
            label="очистить"
            @click="setFilter(null)"
          />
        </p>
      </b-field>
    </b-field>

    <div
      v-for="chart in FilteredCharts"
      :key="chart.id"
      class="box"
    >
      <chart-description
        :chart="chart"
      />
      <b-button
        type="is-info"
        size="is-small"
        @click="EditItem(chart.id)"
      >
        Редактировать
      </b-button>
      <b-button
        type="is-warning"
        size="is-small"
        @click="CloneItem(chart.id)"
      >
        Дублировать
      </b-button>
    </div>
  </div>
</template>

<script>

import ChartDescription from './ChartDescription.vue';
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChartList',
  components: {
    // ChartDataElement,
    ChartDescription
  },
  props: {
  },
  data() {
    return {
      filterChapter: null,
      filterYear: 2023
    };
  },
  computed: {
    ...mapGetters([
      'ChartsFilter'
    ]),
    Charts() {
      const data = this.$store.getters.Items('Chart');
      return data;
    },
    Chapters() {
      const data = this.$store.getters.Items('Chapter');
      return data;
    },
    FilteredCharts() {
      let data = this.Charts

      if (this.filterYear) {
        data = data.filter((d) => d.year === this.filterYear)
      }

      if (this.ChartsFilter) {
        data = data.filter((d) => d.chapter.id === this.ChartsFilter.id)
      }

      return data
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'setFilter'
    ]),

    EditItem(id) {
      this.$router.push(`/charts/${id}`)
    },

    CloneItem(id) {
      this.$store.dispatch('CreateItem', {
        ref: 'CloneChart', formdata: {chart_id: id}, postActions: [['getData', {ref: 'Chart'}]],
      });
    },

  },
};
</script>

<style>
span.it {
  font-style: italic;
}
</style>
