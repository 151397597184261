<template>
  <div class="box">
    <b-field
      grouped
    >
      <b-field
        label="Год"
        :label-position="'on-border'"
      >
        <b-select
          v-model="filterYear"
          size="is-small"
        >
          <option
            v-for="year in [2023, 2022, 2021]"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </b-select>
        <p class="control">
          <b-button
            size="is-small"
            type="is-primary is-light"
            label="очистить"
            @click="filterYear = null"
          />
        </p>
      </b-field>

      <b-field
        label="Глава"
        :label-position="'on-border'"
      >
        <b-select
          v-model="filterChapter"
          size="is-small"
        >
          <option
            v-for="chapter in Chapters"
            :key="'chapter' + chapter.id"
            :value="chapter.id"
          >
            {{ chapter.name }}
          </option>
        </b-select>
        <p class="control">
          <b-button
            size="is-small"
            type="is-primary is-light"
            label="очистить"
            @click="filterChapter = null"
          />
        </p>
      </b-field>

      <b-field>
        <b-button
          size="is-small"
          type="is-success is-light"
          label="Добавить страницу"
          @click="AddItem()"
        />
      </b-field>
    </b-field>

    <div
      v-for="page in FilteredData"
      :key="page.id"
      class="box"
    >
      <page-description
        :page="page"
      />
      <b-button
        type="is-info"
        size="is-small"
        @click="EditItem(page.id)"
      >
        Редактировать
      </b-button>
    </div>
  </div>
</template>

<script>

import PageDescription from './PageDescription.vue';

export default {
  name: 'PageList',
  components: {
    PageDescription
  },
  props: {
  },
  data() {
    return {
      filterChapter: null,
      filterYear: 2023
    };
  },
  computed: {
    Data() {
      const data = this.$store.getters.Items('Page');
      return data;
    },
    FilteredData() {
      let data = this.Data

      if (this.filterYear) {
        data = data.filter((d) => d.year === this.filterYear)
      }

      if (this.filterChapter) {
        data = data.filter((d) => d.chapter.id === this.filterChapter)
      }

      return data
    },
    Chapters() {
      const data = this.$store.getters.Items('Chapter');
      return data;
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    EditItem(id) {
      this.$router.push(`/pages/${id}`)
    },
    AddItem(id) {
      this.$router.push('/pages/create')
    },
  },
};
</script>

<style>
span.it {
  font-style: italic;
}
</style>
